var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"contact-list-item",class:{
    'contact-list-item--is-favorite': _vm.contact.favorite,
    'contact-list-item--is-selected': _vm.isChecked,
  },on:{"click":_vm.onRowClicked}},[_c('Checkbox',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      placement: 'right',
      content: _vm.isChecked ? _vm.unselectText : _vm.selectText,
    }),expression:"{\n      placement: 'right',\n      content: isChecked ? unselectText : selectText,\n    }"}],staticClass:"contact-list-item__checkbox",class:{ 'contact-list-item__checkbox--checked': _vm.isChecked },attrs:{"id":("contact-" + _vm.id),"checked":_vm.isChecked,"label":_vm.checkboxLabelText,"has-hidden-label":""},on:{"change":_vm.onSelectChanged},nativeOn:{"click":function($event){$event.stopPropagation();}}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ placement: 'right', content: _vm.favouriteTooltipText }),expression:"{ placement: 'right', content: favouriteTooltipText }"}],staticClass:"button--icon-only button",class:{ 'contact-list-item__favorite-button': !_vm.contact.favorite },attrs:{"type":"button","aria-label":_vm.favoriteText,"title":_vm.favoriteText},on:{"click":function($event){$event.stopPropagation();return _vm.onFavoriteClicked.apply(null, arguments)}}},[(_vm.contact.favorite)?_c('Icon',{key:_vm.contact.favorite,attrs:{"symbol":"star"}}):_c('Icon',{key:_vm.contact.favorite,attrs:{"symbol":"star-outline"}})],1),_c('div',{staticClass:"row contact-list-item__fields"},[_c('div',{directives:[{name:"test",rawName:"v-test:contactName",arg:"contactName"}],staticClass:"contact-list-item__name col contact-list-item__column",class:{
        'contact-list-item__name--selected': _vm.isChecked,
        'contact-list-item__name--favorite': _vm.contact.favorite,
      }},[(_vm.contact.name)?_c('span',{staticClass:"contact-list-item__column-content"},[_vm._v(_vm._s(_vm.contact.name))]):_c('span',{staticClass:"contact-list-item__no_name"},[_c('translate',[_vm._v("No name")])],1)]),_c('div',{staticClass:"contact-list-item__email-address col contact-list-item__column"},[(_vm.emailAddresses.length)?_c('span',{staticClass:"contact-list-item__email contact-list-item__column-content"},[_c('router-link',{staticClass:"contact-list-item__link",attrs:{"to":_vm.composeRoute(_vm.primaryEmailAddressValue)},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('span',[_vm._v(_vm._s(_vm.primaryEmailAddressValue))])]),(_vm.moreEmailAddressesCount)?_c('span',{staticClass:"contact-list-item__more-email-addresses"},[_vm._v(" +"+_vm._s(_vm.moreEmailAddressesCount)+" ")]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"contact-list-item__phone-number col contact-list-item__column"},[(_vm.contact.phone_number)?_c('a',{staticClass:"contact-list-item__column-content contact-list-item__link",attrs:{"href":("tel:" + (_vm.contact.phone_number))},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.contact.phone_number))]):_vm._e()]),_c('div',{directives:[{name:"test",rawName:"v-test:contactWorkDetails",arg:"contactWorkDetails"}],staticClass:"contact-list-item__work-details col contact-list-item__column"},[_c('span',{staticClass:"contact-list-item__column-content"},[_vm._v(_vm._s(_vm.workDetails))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
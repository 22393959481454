<template>
  <div>
    <div class="container">
      <router-view name="appHeader" />

      <ContactSidebar />

      <main class="main-content">
        <transition name="fade" mode="out-in">
          <router-view name="main" />
        </transition>
      </main>
    </div>
  </div>
</template>

<script>
  import ContactSidebar from '@/components/ContactSidebar/ContactSidebar';

  export default {
    name: 'Contacts',
    components: {
      ContactSidebar,
    },
  };
</script>

<template>
  <div
    v-test:pagination
    v-if="total"
    class="pagination"
    :aria-label="paginationTitleText"
  >
    <p v-test:paginationText class="pagination__large-screen-only">
      {{ paginationText }}
    </p>
    <p v-test:paginationText class="pagination__small-screen-only">
      {{ $store.state.pagination.newPage }}
    </p>

    <button
      v-test:prevPageButton
      class="button button--icon-only pagination__previous"
      type="button"
      :disabled="$store.state.pagination.newPage === 1"
      :aria-label="previousPageText"
      :title="previousPageText"
      @click="previousPage({ router: $router })"
    >
      <Icon symbol="page-next" />
    </button>

    <button
      v-test:nextPageButton
      class="button button--icon-only"
      type="button"
      :disabled="isLastPage"
      :aria-label="nextPageText"
      :title="nextPageText"
      @click="nextPage({ router: $router })"
    >
      <Icon symbol="page-next" />
    </button>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icon';
  import { mapActions } from 'vuex';

  export default {
    components: { Icon },
    computed: {
      isLastPage() {
        return (
          this.$store.state.pagination.newPage >=
          this.$store.getters['pagination/totalPages']
        );
      },
      total() {
        return this.$store.state.pagination.total;
      },
      pageStart() {
        return this.$store.getters['pagination/newPageStart'];
      },
      pageEnd() {
        const end = this.$store.getters['pagination/newPageEnd'];
        return isNaN(end) ? '?' : end.toString();
      },
      paginationTitleText() {
        return this.$gettext('Page navigation');
      },
      previousPageText() {
        return this.$gettext('Previous page');
      },
      nextPageText() {
        return this.$gettext('Next page');
      },
      paginationText() {
        return this.$gettextInterpolate(
          this.$gettext('%{ pageStart }–%{ pageEnd } of %{ total }'),
          {
            pageStart: this.pageStart,
            pageEnd: this.pageEnd,
            total: this.total === null ? '' : this.total,
          }
        );
      },
    },
    methods: {
      ...mapActions({
        nextPage: 'pagination/next',
        previousPage: 'pagination/previous',
      }),
    },
  };
</script>

<style src="./Pagination.scss" lang="scss"></style>

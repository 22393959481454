<template>
  <div class="contact-group-view">
    <ContactList :contacts="contacts" />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import ContactList from '@/components/ContactList/ContactList';
  import { SET_PAGE } from '@/store/pagination';

  export default {
    components: {
      ContactList,
    },
    computed: {
      ...mapGetters(['activeContactGroup', 'contacts']),
    },
    watch: {
      $route() {
        // load new contacts if e.g. a new group was selected in the sidebar
        this.updateContacts();
      },
    },
    created() {
      this.updateContacts();
    },
    methods: {
      ...mapActions(['deselectAllContacts', 'getContactsForGroup']),
      ...mapMutations({ setPage: `pagination/${SET_PAGE}` }),
      updateContacts() {
        const page = parseInt(this.$route.query.page) || 1;
        this.setPage(page);

        this.getContactsForGroup({
          id: this.$route.params.group,
          query: this.$route.query.query,
        });
      },
    },
  };
</script>

<style src="./ContactGroupView.scss" lang="scss" />

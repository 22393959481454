<template>
  <div class="skeleton-list" :class="densityOption.class">
    <div
      class="panel__section-item skeleton-list__item"
      v-for="i in itemCount"
      :key="i"
      :id="`item-${i}`"
    >
      <div class="loader skeleton-list__loader" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'SkeletonList',
    props: {
      itemCount: {
        type: Number,
        required: true,
      },
    },
    computed: {
      ...mapGetters('layout', ['densityOption']),
    },
  };
</script>

<style src="./SkeletonList.scss" lang="scss"></style>

<template>
  <div class="context-switcher">
    <div
      class="context-switcher__switch"
      :key="value"
      v-for="{ text, value } in options"
    >
      <button
        type="button"
        :key="value"
        class="context-switcher__switch-button button"
        @click="$emit('change', value)"
        v-test:contextSwitchButton
      >
        <template v-if="value === densityCompact">
          <Icon
            v-show="active === densityCompact"
            class="context-switcher__icon"
            symbol="layout-compact-active"
          />
          <Icon
            v-show="active !== densityCompact"
            class="context-switcher__icon"
            symbol="layout-compact-inactive"
          />
        </template>
        <template v-else>
          <Icon
            v-show="active === densityDefault"
            class="context-switcher__icon"
            symbol="layout-default-active"
          />
          <Icon
            v-show="active !== densityDefault"
            class="context-switcher__icon"
            symbol="layout-default-inactive"
          />
        </template>
      </button>
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icon';
  import { DENSITY_DEFAULT, DENSITY_COMPACT } from '@/store/layout/layout';

  export default {
    name: 'ContextSwitcher',
    components: {
      Icon,
    },
    props: {
      options: {
        type: Array,
        default: () => [],
      },
      active: {
        type: String,
        required: true,
      },
    },
    computed: {
      densityCompact() {
        return DENSITY_COMPACT;
      },
      densityDefault() {
        return DENSITY_DEFAULT;
      },
    },
  };
</script>

<style src="./ContextSwitcher.scss" lang="scss"></style>

/** Best effort URL sanitization. */
export function sanitizeUrl(s: string): string | null {
  if (!s.includes('://')) {
    // add a scheme to handle bare domains
    s = `https://${s}`;
  }
  try {
    return new URL(s).toString();
  } catch (e) {
    return null;
  }
}

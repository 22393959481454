























































  import { CONTACT_IMPORT_FILE_TOO_LARGE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import FileUpload from '@/components/FileUpload/FileUpload.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import Modal from '@/components/Modal.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { CONTACTS_GROUP } from '@/router/named-routes';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'ContactImportModal',
    components: {
      FileUpload,
      Icon,
      LoadingButton,
      Modal,
    },
    mixins: [asyncActionsMixin],
    data() {
      return {
        files: [] as File[],
        validate: false,
      };
    },
    computed: {
      acceptedFileTypes(): string[] {
        return ['text/vcard', '.vcf', 'text/csv', '.csv'];
      },
      form(): HTMLFormElement {
        return this.$refs.form as HTMLFormElement;
      },
      modal(): any {
        return this.$refs.modal as any;
      },
      requiredErrorMessage(): string {
        return this.$gettext('Please select a file before continuing');
      },
      unacceptedFileErrorMessage(): string {
        return this.$gettext(
          "File format isn't supported. Please use vCard or csv file."
        );
      },
    },
    methods: {
      ...mapActions(['importContacts', 'setToastMessage']),
      submitForm: asyncAction('importingContacts', async function (this: any) {
        this.validate = true;
        if (!this.form.checkValidity()) {
          return;
        }

        return this.importContacts({
          files: [...this.files],
        })
          .then(({ contactGroup }: { contactGroup: ContactGroup }) => {
            this.setToastMessage({
              message: this.$gettextInterpolate(
                this.$ngettext(
                  'Contact imported',
                  '%{ n } contacts imported',
                  contactGroup.contact_count
                ),
                { n: contactGroup.contact_count }
              ),
            });
            this.$router.push({
              name: CONTACTS_GROUP,
              params: { group: contactGroup.id },
            });
            this.closeModal();
          })
          .catch((err: AxiosError) => {
            this.setToastMessage({
              message:
                getErrorCode(err) === CONTACT_IMPORT_FILE_TOO_LARGE
                  ? this.$gettext(
                      'File size limit exceeded. Maximum file size is 1MB.'
                    )
                  : this.$gettext(
                      'Could not import contacts, please try again.'
                    ),
            });
          });
      }),
      openModal() {
        if (!this.modal.isOpen) {
          this.modal.setBeforeCloseCallback(this.resetState);
          this.modal.toggleModal();
        }
      },
      closeModal() {
        if (this.modal.isOpen) {
          this.modal.toggleModal();
        }
      },
      resetState() {
        this.files = [];
        this.validate = false;
      },
    },
  });

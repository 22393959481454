<template>
  <li
    class="contact-list-item"
    :class="{
      'contact-list-item--is-favorite': contact.favorite,
      'contact-list-item--is-selected': isChecked,
    }"
    @click="onRowClicked"
  >
    <Checkbox
      :id="`contact-${id}`"
      class="contact-list-item__checkbox"
      :class="{ 'contact-list-item__checkbox--checked': isChecked }"
      :checked="isChecked"
      :label="checkboxLabelText"
      has-hidden-label
      @change="onSelectChanged"
      @click.native.stop
      v-tooltip="{
        placement: 'right',
        content: isChecked ? unselectText : selectText,
      }"
    />
    <button
      type="button"
      @click.stop="onFavoriteClicked"
      class="button--icon-only button"
      :class="{ 'contact-list-item__favorite-button': !contact.favorite }"
      :aria-label="favoriteText"
      :title="favoriteText"
      v-tooltip="{ placement: 'right', content: favouriteTooltipText }"
    >
      <Icon v-if="contact.favorite" symbol="star" :key="contact.favorite" />
      <Icon v-else symbol="star-outline" :key="contact.favorite" />
    </button>

    <div class="row contact-list-item__fields">
      <div
        class="contact-list-item__name col contact-list-item__column"
        :class="{
          'contact-list-item__name--selected': isChecked,
          'contact-list-item__name--favorite': contact.favorite,
        }"
        v-test:contactName
      >
        <span v-if="contact.name" class="contact-list-item__column-content">{{
          contact.name
        }}</span>
        <span v-else class="contact-list-item__no_name"
          ><translate>No name</translate></span
        >
      </div>
      <div
        class="contact-list-item__email-address col contact-list-item__column"
      >
        <span
          v-if="emailAddresses.length"
          class="contact-list-item__email contact-list-item__column-content"
        >
          <router-link
            :to="composeRoute(primaryEmailAddressValue)"
            @click.native.stop
            class="contact-list-item__link"
          >
            <span>{{ primaryEmailAddressValue }}</span>
          </router-link>
          <span
            v-if="moreEmailAddressesCount"
            class="contact-list-item__more-email-addresses"
          >
            +{{ moreEmailAddressesCount }}
          </span>
        </span>
      </div>
      <div
        class="contact-list-item__phone-number col contact-list-item__column"
      >
        <a
          v-if="contact.phone_number"
          :href="`tel:${contact.phone_number}`"
          class="contact-list-item__column-content contact-list-item__link"
          @click.stop
          >{{ contact.phone_number }}</a
        >
      </div>
      <div
        class="contact-list-item__work-details col contact-list-item__column"
        v-test:contactWorkDetails
      >
        <span class="contact-list-item__column-content">{{ workDetails }}</span>
      </div>
    </div>
  </li>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import Checkbox from '@/components/form/Checkbox';
  import Icon from '@/components/Icon/Icon';
  import { CONTACTS_CONTACT, MAIL_COMPOSE } from '@/router/named-routes';

  export default {
    name: 'ContactListItem',
    components: { Checkbox, Icon },
    props: {
      contact: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters(['selectedContactsIds']),
      route() {
        return {
          name: CONTACTS_CONTACT,
          params: { contact: this.contact.id },
          query: { query: this.$route.query.query },
        };
      },
      isChecked() {
        return this.selectedContactsIds.indexOf(this.contact.id) > -1;
      },
      checkboxLabelText() {
        return this.$gettextInterpolate(this.$gettext('Select %{ name }'), {
          name: `${this.contact.display_name}`,
        });
      },
      publicKeyPresentText() {
        return this.$gettext('Public key present');
      },
      favoriteText() {
        return this.$gettext('Favorite');
      },
      moreEmailAddressesCount() {
        return Math.max(0, this.emailAddresses.length - 1);
      },
      workDetails() {
        if (this.contact.company_name && this.contact.job_title) {
          return `${this.contact.company_name} - ${this.contact.job_title}`;
        }
        return this.contact.company_name || this.contact.job_title;
      },
      emailAddresses() {
        return this.contact.email_addresses;
      },
      primaryEmailAddressValue() {
        return this.emailAddresses[0]?.value || '';
      },
      favouriteTooltipText() {
        return this.contact.favorite
          ? this.$gettext('Remove from favorites')
          : this.$gettext('Add to favorites');
      },
      selectText() {
        return this.$gettext('Select');
      },
      unselectText() {
        return this.$gettext('Unselect');
      },
    },
    methods: {
      ...mapActions([
        'selectContact',
        'expandContactSelection',
        'setContactFavoriteStatus',
      ]),
      onRowClicked() {
        this.$router.push(this.route);
      },
      onSelectChanged(value, shiftKey) {
        if (shiftKey) {
          this.expandContactSelection({
            contact: this.contact,
            value,
          });
        } else {
          this.selectContact({
            contact: this.contact,
            value,
          });
        }
      },
      onFavoriteClicked() {
        this.setContactFavoriteStatus({
          id: this.contact.id,
          favorite: !this.contact.favorite,
        });
      },
      composeRoute(email) {
        return {
          name: MAIL_COMPOSE,
          query: { ...this.$route.query, mailTo: email },
          params: { ...this.$route.params, backRoute: this.$route },
        };
      },
    },
  };
</script>

<style src="./ContactListItem.scss" lang="scss"></style>

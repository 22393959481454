<template>
  <SearchInputField
    :label="searchLabelText"
    :initial-query="$route.query.query"
    @search="search"
  />
</template>

<script>
  import SearchInputField from '@/components/SearchForm/SearchInputField';
  import contactGroupMixin from '@/mixins/contact-group-mixin';
  import { CONTACTS_SEARCH } from '@/router/named-routes';

  export default {
    name: 'ContactSearchForm',
    components: {
      SearchInputField,
    },
    mixins: [contactGroupMixin],
    computed: {
      searchLabelText() {
        return this.$gettext('Search contacts');
      },
    },
    methods: {
      search(query) {
        if (query) {
          // Search is always across all groups, so unselect the current group
          // otherwise the scoped compose button is wrong.
          this.$store.dispatch('setActiveContactGroup', { id: null });
          this.$router.push({
            name: CONTACTS_SEARCH,
            query: { query },
          });
        }
      },
    },
  };
</script>
